(function () {
    'use strict';

    angular
        .module('smartermail')
        .component('userWithContext', {
            templateUrl: '/interface/app/email/message-view-components/user-with-context.component.html',
            controller: userWithContext,
            bindings: {
                type: '<',
                email: '<',
                name: '<',
                isChat: '<',
                isAlias: '<',
                displayEmail: '<',
                displayAs: '<',
                createFilter: '&',
                addToFilter: '&',
                isBlockedSender: '<',
                messageFrom: '<',
                messageUid: '<',
                messageFolder: '<',
                messageData: '<',
                showTrust: '<',
            },
        });

    /* @ngInject */
    function userWithContext($rootScope, $scope, $element, $filter, $translate, $http, $timeout, $state, $mdDialog, $compile, $log,
        userDataService, coreData, coreDataContacts, authStorage, $localStorage, errorHandling, successHandling,
        popupService, restrictedSenders, coreDataSettings, emailFunctions, emailNavigation, claimsService, chatProviderFactory) {

        var vm = this;
        vm.open = false;
        vm.options = [];
        vm.status = null;
        vm.updateStatus = updateStatus;
        vm.fullDisplayAddress = fullDisplayAddress;
        vm.features = {};
        vm.isBlocked = false;
        vm.isTrusted = false;
        vm.trustSource = "NONE";
        vm.openContextMenu = openContextMenu;
        vm.onMenuHidden = onMenuHidden;
        vm.isGroup = isGroup;
        vm.fullDisplayAddressText = fullDisplayAddress();
        vm.contact = null;

        activate();

        async function activate() {
            $scope.$on('user-context-menu.options-changed', () => vm.updateStatus());
            $scope.$watch("[name, email, isTrusted]", () => vm.fullDisplayAddressText = fullDisplayAddress());

            if (!vm.isChat) {
                $scope.$on('xmpp.contacts-changed', () => vm.updateStatus());
            }

            try {
                await Promise.all([restrictedSenders.init(), userDataService.init()]);
                vm.isBlocked = restrictedSenders.isBlockedSender(vm.email);
                if (vm.type == 'from') {
                    let isMessageFrom = vm.messageFrom && vm.email &&
                        (vm.messageFrom.toLowerCase() === vm.email.toLowerCase() || vm.messageFrom.toLowerCase().indexOf('<' + vm.email.toLowerCase() + '>') > -1);
                    if (isMessageFrom && vm.messageData && vm.messageData.message && vm.messageData.message.trustInfo) {
                        vm.isTrusted = vm.messageData.message.trustInfo.trustedSenderLevel > 0;
                        switch (vm.messageData.message.trustInfo.trustedSenderLevel) {
                            case 1: vm.trustSource = "USER"; break;
                            case 2: vm.trustSource = "CONTACTS"; break;
                            case 3: vm.trustSource = "DOMAIN"; break;
                            case 4: vm.trustSource = "GAL"; break;
                            case 5: vm.trustSource = "SYSTEM"; break;
                            default: vm.trustSource = "NONE"; break;
                        }
                    }
                    else {
                        var result = await restrictedSenders.isTrustedSender(vm.email);
                        vm.isTrusted = result.isTrusted;
                        vm.trustSource = result.trustSource;
                    }
                }
                vm.features = userDataService.user.settings.features;
                $scope.$on('chat.user-status-changed', (e, data) => {
                    if (data.all) {
                        vm.updateStatus();
                    } else if (data.email === vm.email && data.status) {
                        vm.status = data.status;
                        $scope.$applyAsync();
                    }
                });
                vm.updateStatus();
            } catch (err) {
                console.error(err);
            }
        }

        // Implementation
        function updateStatus() {
            if (!vm.isChat && vm.email) {
                vm.status = chatProviderFactory.getUserStatus(vm.email);
            }
            vm.fullDisplayAddressText = fullDisplayAddress();
            vm.options = generateItems();
            $scope.$applyAsync();
        }

        function fullDisplayAddress() {
            return getFullDisplayAddress(vm.name, vm.email);
        }

        function getFullDisplayAddress(name, email) {
            if (vm.isTrusted != undefined && !vm.isTrusted && !(vm.isChat && !vm.displayEmail)) {
                if (name && email)
                    return filterName(name) + ' <' + email.trim() + '>';
            }
            if (vm.isGroup())
                return name.split("|")[0].trim();
            if (name && email !== name) { return filterName(name); }
            if (email) { return email.trim(); }
            if (name) { return filterName(name); }
            return name;

            function filterName(s) {
                return s.trim().replace(/:$/gm, '');
            }
        }

        function isGroup() {
            return !vm.email &&
                vm.name &&
                vm.name.indexOf("|") !== -1;
        }

        function generateItems() {
            var retVal = [];
            var emailAddress = vm.email;
            var name = vm.name;

            if (!emailAddress)
                return null;

            const converted = restrictedSenders.convertEmailFromDomainAlias(emailAddress);
            const isSelf = converted.email == coreData.user.emailAddress;

            // Email functions
            retVal.push({ text: $translate.instant('SEND_EMAIL'), func: onSendEmail, order: 10 });

            if (!isSelf && vm.features.enableChat && !vm.isChat && !claimsService.impersonating() &&
                chatProviderFactory.getUserStatus(emailAddress)) {
                retVal.splice(1, 0, { text: $translate.instant('START_CHAT'), func: onStartChat, order: 11 });
                
            }

            // Contact functions
            if (!isSelf && !vm.isChat) {
                retVal.push({ text: $translate.instant('ADD_CONTACT'), func: onAddToContacts, order: 20 });
            }

            // Calendar functions
            if (!isSelf && !vm.isAlias) {
                retVal.push({ text: $translate.instant('INVITE_TO_APPOINTMENT'), func: onInviteToEvent, order: 20 });
            }

            if (vm.type == 'from' && !isSelf && !vm.isChat) {
                retVal.push({ divider: true });


                //Trust/Untrust
                if (vm.showTrust) {
                    var impliedTrust = vm.trustSource !== 'NONE' && vm.trustSource !== 'USER';
                    if (!vm.isBlocked && !vm.isTrusted && !impliedTrust)
                        retVal.push({ text: $translate.instant('TRUST_SENDER'), func: onTrustSender, order: 20 });
                    if (vm.isTrusted && !impliedTrust)
                        retVal.push({ text: $translate.instant('UNTRUST_SENDER'), func: onUntrustSender, order: 20 });
                }

                // Block/Unblock sender
                if (vm.isBlocked) {
                    retVal.push({ text: $translate.instant('UNBLOCK_SENDER'), func: onUnblockSender, order: 20 });
                } else if (!vm.isTrusted) {
                    retVal.push({ text: $translate.instant('BLOCK_SENDER'), func: onBlockSender, order: 20 });
                }

                // Create/Add sender to filter
                if (typeof vm.createFilter === 'function')
                    retVal.push({ text: $translate.instant('CREATE_FILTER'), func: onCreateFilter, order: 20 });
                if (typeof vm.addToFilter === 'function')
                    retVal.push({ text: $translate.instant('ADD_SENDER_TO_FILTER'), func: onAddToFilter, order: 20 });
            }

            return retVal;
        }

        function openContextMenu(event) {
            var elementToAdd = `<user-with-context-menu class="user-with-context-menu st-context-menu" 
                ng-class="{'context-open': $ctrl.open}"
                email="$ctrl.email"
                options="$ctrl.options"
                on-hidden="$ctrl.onMenuHidden()"></user-with-context>`;
            var elementCompiled = $compile(elementToAdd)($scope);

            var parentElement = $('#context-menu-area');
            if (!parentElement || !parentElement.length)
                return;

            parentElement.append(elementCompiled);
            $timeout(function () {
                var dElement = $(elementCompiled);
                var offset = $($element).offset();
                var bottom = offset.top + 22;
                if (bottom + dElement.height() > window.innerHeight) {
                    var upperHeight = bottom - 13 + (window.innerHeight - (bottom + dElement.height()));
                    if (upperHeight < 0) {
                        upperHeight = 5;
                    }
                    dElement[0].style.top = upperHeight + 'px';
                } else {
                    dElement[0].style.top = bottom + 'px';
                }
                var menuWidth = dElement.width();
                if (offset.left + menuWidth > window.innerWidth) {
                    dElement[0].style.left = offset.left - 5 + (window.innerWidth - (offset.left + menuWidth)) + 'px';
                } else {
                    dElement[0].style.left = offset.left + 'px';
                }
                vm.open = true;
            });
        }

        function onMenuHidden(event) {
            vm.open = false;
        }

        function onSendEmail() {
            const emailAddress = vm.email;
            const name = vm.name;
            const displayAs = vm.displayAs || vm.name;

            const packet = emailNavigation.makeComposePacket({
                to: {
                    emailAddress: emailAddress,
                    displayAs: displayAs
                }
            });
            const url = emailNavigation.getPopoutComposeUrl(packet);
            window.open(url, '', 'resizable=1, ' + popupService.dimensions.email);
        }

        async function onAddToContacts() {
            const emailAddress = vm.email;
            const name = vm.name;
            let allContacts = await coreDataContacts.getAllContacts();
            if (allContacts.results.some((contact) => contact.emailAddressList.includes(emailAddress))) {
                var params = {
                    controller: "contactAlreadyExistsController",
                    controllerAs: "ctrl",
                    templateUrl: "app/email/modals/contact-already-exists.dlg.html",
                    clickOutsideToClose: true
                };
                $mdDialog.show(params).then(
                    function (result) {
                        var baseUrl = window.location.href.substring(0, window.location.href.indexOf('#/'));
                        authStorage.setPopoutData({
                            primaryEmail: emailAddress,
                            displayAs: name && name.length > 0 ? name : emailAddress,
                            activateSave: true
                        });
                        window.open(baseUrl + '#/popout/contact/new', 'new', 'resizable=1, ' + popupService.dimensions.contact);
                    },
                    function () { });;
            } else {
                var baseUrl = window.location.href.substring(0, window.location.href.indexOf('#/'));
                authStorage.setPopoutData({
                    primaryEmail: emailAddress,
                    displayAs: name && name.length > 0 ? name : emailAddress,
                    activateSave: true
                });
                window.open(baseUrl + '#/popout/contact/new', 'new', 'resizable=1, ' + popupService.dimensions.contact);
            }
        }

        function onInviteToEvent() {
            const emailAddress = vm.email;
            const name = vm.name;
            localStorage.apptPopout = JSON.stringify({
                owner: null,
                calId: null,
                id: null
            });
            if (emailAddress !== coreData.user.emailAddress) {
                localStorage.apptPopout = JSON.stringify({
                    data: {
                        organizerName: coreData.user.displayName,
                        organizerEmail: coreData.user.emailAddress,
                        attendees: [{
                            name: name,
                            email: emailAddress,
                            status: 0,
                            conflict: false,
                            nonavail: null
                        }]
                    }
                });
            }
            window.open(stSiteRoot + '#/popout/appointment/new', '_blank', 'resizable=1, ' + popupService.dimensions.calendar);
        }

        async function onTrustSender() {
            try {
                const emailAddress = vm.email.toLowerCase();
                var isSender = false;
                if (vm.messageFrom) {
                    var from = vm.messageFrom.toLowerCase();
                    isSender = from === emailAddress || from.indexOf('<' + emailAddress + '>') > -1;
                }

                await emailFunctions.trustSender(null, vm.messageFolder, [vm.messageUid], isSender);
                vm.isTrusted = true;
                if (vm.messageData && vm.messageData.message && vm.messageData.message.trustInfo && vm.messageData.message.trustInfo.trustedSenderLevel < 1)
                    vm.messageData.message.trustInfo.trustedSenderLevel = 1;
                $timeout(function () { vm.options = generateItems(); });
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function onUntrustSender() {
            try {
                const emailAddress = vm.email.toLowerCase();
                var isSender = false;
                if (vm.messageFrom) {
                    var from = vm.messageFrom.toLowerCase();
                    isSender = from === emailAddress || from.indexOf('<' + emailAddress + '>') > -1;
                }

                await emailFunctions.untrustSender(null, vm.messageFolder, [vm.messageUid], isSender);
                vm.isTrusted = false;
                if (vm.messageData && vm.messageData.message && vm.messageData.message.trustInfo)
                    vm.messageData.message.trustInfo.trustedSenderLevel = 0;
                $timeout(function () { vm.options = generateItems(); });
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function onBlockSender() {
            try {
                const emailAddress = vm.email.toLowerCase();
                var blocked = {};
                blocked[emailAddress] = [vm.messageUid];

                var isSender = false;
                if (vm.messageFrom) {
                    var from = vm.messageFrom.toLowerCase();
                    isSender = from === emailAddress || from.indexOf('<' + emailAddress + '>') > -1;
                }

                const wasBlocked = await emailFunctions.blockSender(null, vm.messageFolder, blocked, false, false, isSender);
                if (wasBlocked) {
                    vm.isBlocked = true;
                    $timeout(function () { vm.options = generateItems(); });
                }
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function onUnblockSender() {
            try {
                const emailAddress = vm.email.toLowerCase();
                var unblocked = {};
                unblocked[emailAddress] = [vm.messageUid];
                var isSender = false;
                if (vm.messageFrom) {
                    var from = vm.messageFrom.toLowerCase();
                    isSender = from === emailAddress || from.indexOf('<' + emailAddress + '>') > -1;
                }

                await emailFunctions.unblockSender(null, vm.messageFolder, unblocked, isSender);
                vm.isBlocked = false;
                $timeout(function () { vm.options = generateItems(); });
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function onCreateFilter() {
            if (typeof vm.createFilter === 'function') {
                vm.createFilter();
            }
        }

        function onAddToFilter() {
            if (typeof vm.addToFilter === 'function') {
                vm.addToFilter();
            }
        }

        function onStartChat(e) {
            chatProviderFactory.openLink(e, vm.email);
        }

        function onStartVideoChat() {
            $state.go('index.chat');
            $rootScope.$broadcast('xmpp.start-video-chat', { jid: vm.email, contact: vm.contact });
        }

    }

})();